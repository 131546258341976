// JavaScript
import { gsap } from 'gsap';

export function initLoader() {
  let loaderOverlay = document.getElementById('loader-overlay');
  let percentageText = document.getElementById('percentage');
  let progressBar = document.getElementById('progress-bar-component');
  let assetsLoaded = 0;
  let totalAssets = document.images.length;
  let maxNaturalPercentage = 80; // We'll load assets up to 80%

  function updateLoader() {
    assetsLoaded++;
    let percent = Math.min(Math.round((assetsLoaded / totalAssets) * 100), maxNaturalPercentage); // Cap at 80%

    gsap.to(percentageText, {
      textContent: percent,
      duration: 0.5,
      snap: { textContent: 1 },
      ease: 'power1.out',
    });

    gsap.to(progressBar, {
      width: percent + '%',
      duration: 0.5,
      ease: 'power1.out',
    });

    if (assetsLoaded === totalAssets) {
      gsap.delayedCall(2, () => {
        gsap.to(percentageText, {
          textContent: 100,
          duration: 1,
          snap: { textContent: 1 },
          ease: 'power1.out',
        });

        gsap.to(progressBar, {
          width: '100%',
          duration: 1,
          ease: 'power1.out',
        });

        gsap.to(loaderOverlay, {
          opacity: 0,
          duration: 1,
          delay: 0.5,
          onComplete: () => {
            loaderOverlay.style.display = 'none';
            document.body.classList.remove('overflow-hidden');
            $('.header.top-header').css('opacity','1');
            $('.home-section.banner-section .section-inner-wrp').css('opacity','1');
            if (matchMedia('screen and (max-width: 992px)').matches) {
              $('.header.pop-up-header').css('opacity','1');
            }
          },
        });
      });
    }
  }

  Array.from(document.images).forEach((img) => {
    if (img.complete) {
      updateLoader();
    } else {
      img.addEventListener('load', updateLoader);
    }
  });
}
