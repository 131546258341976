export default {
  init() {
    // JavaScript to be fired on the about us page
  },
  finalize() {
    // JavaScript to be fired on the home page, after the init JS
    teamSectionScroll($('.scroll-high'), 150 , 200);
  },
};

$(window).on('resize', function () {
  teamSectionScroll($('.scroll-high'), 150 , 200);
});

$(window).on('load', function () {
  teamSectionScroll($('.scroll-high'), 150 , 200);

});

$(window).on('scroll', function() {
  teamSectionScroll($('.scroll-high'), 150 , 200);
});

function teamSectionScroll(element,mobileValue,desktopValue) {
  function easeOutCubic(t) {
    return 1 - Math.pow(1 - t, 3);
  }

  var leftItems = element;
  var divHeightrow = $('.row-work');
  var divHeight = divHeightrow.outerHeight();

  if (leftItems.length) {
    var divBottom =  $(window).scrollTop() + $(window).height() - leftItems.offset().top;
  }

  if (divBottom > 0 && divHeight > divBottom) {
    var scrollHeight = divHeight - divBottom;
    var scrollPercentage = scrollHeight/divHeight;
    var easedScrollPercentage = easeOutCubic(scrollPercentage);
    var translateY;

    if (matchMedia('screen and (max-width: 1400px)').matches) {
      translateY = easedScrollPercentage * mobileValue;

    } else {
      translateY = easedScrollPercentage * desktopValue;
    }
    leftItems.css('transform', 'translateY(-' + translateY + 'px)');
  }
}